.mobile-grid-wrapper {
  grid-template-columns: repeat(12, 1fr);

  .mobile-type {
    grid-column: span 4;
  }

  .mobile-prefix {
    grid-column: span 3;

    & + .mobile-details {
      grid-column: span 5;
    }

    &:first-of-type + .mobile-details {
      grid-column: span 9;
    }
  }

  .mobile-details {
    grid-column: span 8;

    &:only-of-type {
      grid-column: span 12;
    }
  }
}
